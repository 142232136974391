<template>
  <div class="elite">
    <logged-heard></logged-heard>
    <div class="enterprises">
      <div class="enterprises-center position-relative">
        <img
          src="../../assets/bg-img/ht-1.png"
          alt=""
          class="bg-img position-absolute bottom-0 left-0"
        />
        <div class="enterprises-posi position-absolute top-0 left-0">
          <div class="posi-choice text-main">
            <div class="font-bold title-posi">
              申请成为精英
              <span>精英是平台用户的重要标签，能够获得更多优质机会</span>
            </div>
            <div>以下条件，满足任意三项即可申请</div>
            <el-checkbox-group v-model="checkList">
              <div class="check-div" v-for="item in dataCheck" :key="item.value">
                <el-checkbox :label="item.value">{{ item.label }}</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div class="enterprises-protocol direction">
            <div>
              <el-checkbox v-model="checked"
                >本人承诺，以上信息真实可信，如有虚假，平台有权将我的账号做冻结处理。</el-checkbox
              >
            </div>
            <div
              class="flex-center text-white bg-blue font-lighter submit-button pointer"
              @click="subminClick"
            >
              马上申请
            </div>
          </div>
        </div>
      </div>
      <mask-model
        title="申请已提交"
        :btnCancel="false"
        ref="mask"
        sureContent="返回"
        width="482px"
      >
        <div class="mask-soloe text-main font-normal" v-if="dachangObj">  {{ dachangObj.status == 0 ? "待审核" : dachangObj.status == 1 ? "通过" : "驳回" }}</div>
      </mask-model>
    </div>
    <feet-model></feet-model>
  </div>
</template>

<script>
import loggedHeard from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import maskModel from "../../components/mask.vue";
import api from "../../api/tool";
import apiElit from "../../api/elit";
export default {
  components: { loggedHeard, feetModel, maskModel },

  data() {
    return {
      checkList: [],
      dataCheck: [],
      checked: false,
      dataShow:false,
      dachangObj:null
    };
  },

  created() {
    this.getList();
    this.getElite()
  },

  mounted() {},

  methods: {
    // 获取申请状态
    getElite(){
      apiElit.getElite().then(res=>{
          if (res.code == 200) {
          if (res.data) {
            if (res.data.status == 1 || res.data.status == 0) {
              this.dataShow = true;
            }
            let reason = this.dataCheck.filter((item) => {
              return res.data.reason.includes(item.label);
            });
            this.checkList=reason.map(item=>{
              return item.value
            })
            // console.log(this.checkList)
            this.dachangObj = res.data;
          } else {
            this.dataShow = false;
          }
        }
      })
    },
    subminClick() {
        // 判断是否以及申请了
      if (this.dataShow) {
        this.$refs.mask.show();
        return;
      }
      if (!this.checked) {
        this.$util.msg("请勾选承诺", "error");
        return;
      } else if (this.checkList.length < 3) {
        this.$util.msg("最少选择三项", "error");
        return;
      }

       // 从原始数组中取出选中的值
      let reason = this.dataCheck.filter((item) => {
        return this.checkList.includes(item.value);
      });
      let personOne = reason.map((item) => {
        return item.label;
      });

      apiElit.appElite({ reason: personOne }).then((res) => {
        if (res.code == 200) {
          this.getElite()
          this.$refs.mask.show();
        }
      });
    },
    // 获取列表数据
    getList() {
      api.getUserConfig().then((res) => {
        if (res.code == 200) {
          this.dataCheck = res.data.reason.options;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.elite {
  width: 100%;
  min-height: 100vh;
  background: #f6f7f8;
  .enterprises {
    width: 100%;
    padding: 1.25rem 11.875rem 2.125rem 11.875rem;
    .enterprises-center {
      width: 100%;
      height: 860px;
      background: #ffffff;
      box-shadow: 1px 0px 20px 1px #f6f6f8;
      .bg-img {
        width: 100%;
        height: 41.5rem;
        z-index: 0;
      }
      .enterprises-posi {
        width: 100%;
        height: 100%;
        z-index: 1;
        padding-top: 2.625rem;
        .posi-choice {
          padding-left: 13.1875rem;
          font-size: 15px;
          .title-posi {
            margin-bottom: 2.5rem;
            span {
              display: inline-block;
              color: #aeb1bb;
              font-size: 0.8125rem;
              margin-left: 1.875rem;
            }
          }
          .check-div {
            margin-top: 2.3125rem;
          }
        }
        .enterprises-protocol {
          width: 100%;
          margin-top: 4.375rem;
          .submit-button {
            width: 20.25rem;
            height: 2.625rem;
            margin-top: 2.5rem;
          }
        }
      }
    }
    .mask-soloe {
      margin-top: 1.0625rem;
      margin-bottom: 2rem;
    }
  }
}
</style>
